
import logo from '../assets/imgs/logo.png'
import logoSecondary from '../assets/imgs/Logo10-2.svg'
export default {
  props: {
    isSecondary: {
      type: Boolean,
      default: false
    },
    isScale: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      logo,
      logoSecondary
    }
  },
  methods: {
    toggleLogo() {
      this.$emit('click-logo')
    },
  }
}
