
import {
  ACTION_SIGN_IN_WITH_FACEBOOK,
  ACTION_SIGN_IN_WITH_GOOGLE, ACTION_SIGN_IN_WITH_VETC,
} from '@/utils/store.action'
import RudderStackHandler from '@/mixins/RudderStackHandler'
import platform from 'platform'
import config from '@/utils/config'
import OauthVetc from "@/pages/auth/oauth/oauth-vetc";
import CleverTapHandler from "@/mixins/CleverTapHandler";

export default {
  components: {OauthVetc},
  mixins: [RudderStackHandler, CleverTapHandler],
  props: {
    isPopup: {
      type: Boolean,
      default: false
    },
    isOauth: {
      type: Boolean,
      default: false
    },
    requireConsent: {
      type: Number,
      default: 1
    }
  },
  methods: {
    async signInWithGoogle() {
      try {
        const {ok, data} = await this.$store.dispatch(ACTION_SIGN_IN_WITH_GOOGLE)
        if (ok) {
          const {data: {user}} = data
          this.rudderTrack(config.newEventName.login_clicked, {
            method: 'google',
            status: 'pass',
            name: user.fullName,
            phone: user.phoneNumber,
            email: user.email,
            user_name: user.username,
            gender: user.gender,
            userID: user.uid,
            forumUserID: user.forumUserId,
            account_number: user?.vetcInfo
          })
          this.clevertapTrack(config.eventTypeTracking.log_in_clicked, {
            button_clicked: 'Click button login with google',
            screen_name: 'Login',
            user_name: user.uid,
            sign_in_method: 'Google'
          })
          if (!this.isOauth) {
            if (this.$route.query.from) {
              window.location.href = this.$route.query.from
            } else {
              if (this.isPopup) {
                this.$emit('close-modal-signin')
              } else {
                await this.$router.push('/')
              }
            }
          } else {
            this.$emit('handle-oauth')
          }
        }
      } catch (err) {
        const status = err?.response?.status
        if (status && status !== config.httpCode.OK) {
          this.$toasted.clear()
          this.$toasted.show('Có lỗi xảy ra trong quá trình đăng nhập. Vui lòng thử lại', {
            type: 'error',
            duration: 3000,
            position: 'top-center'
          })
        }
      }
    },
    async signInWithVETC() {
      if (this.$route.query.from) {
        localStorage.setItem('fromSource', this.$route.query.from)
        localStorage.setItem('isOauth', this.isOauth)
        this.$emit('save-local-storage-oauth-vetc')
      }
      const redirect_uri = window.location.origin + '/auth/oauth/oauth-vetc'
      window.location.href = `${config.VETCAuthConfig.domain}/auth/realms/${config.VETCAuthConfig.realms}/protocol/openid-connect/auth?client_id=${config.VETCAuthConfig.applicationId}&redirect_uri=${encodeURI(redirect_uri)}&scope=openid&response_type=code&response_mode=query&from=abc`
    },
    async signInWithFacebook() {
      try {
        const {ok, data} = await this.$store.dispatch(ACTION_SIGN_IN_WITH_FACEBOOK)
        if (ok) {
          const {data: {user}} = data
          this.rudderTrack(config.newEventName.login_clicked, {
            method: 'facebook',
            status: 'pass',
            name: user.fullName,
            phone: user.phoneNumber,
            user_name: user.username,
            email: user?.email || '',
            gender: user.gender,
            userID: user.uid,
            forumUserID: user.forumUserId,
            account_number: user?.vetcInfo
          })
          this.clevertapTrack(config.eventTypeTracking.log_in_clicked, {
            button_clicked: 'Click button login with facebook',
            screen_name: 'Login',
            user_name: user.uid,
            sign_in_method: 'Facebook'
          })
          if (!this.isOauth) {
            if (this.$route.query.from) {
              window.location.href = this.$route.query.from
            } else {
              if (this.isPopup) {
                this.$emit('close-modal-signin')
              } else {
                await this.$router.push('/')
              }
            }
          } else {
            this.$emit('handle-oauth')
          }
        }
      } catch (err) {
        const status = err?.response?.status
        if (status && status !== config.httpCode.OK) {
          this.$toasted.clear()
          this.$toasted.show('Có lỗi xảy ra trong quá trình đăng nhập. Vui lòng thử lại', {
            type: 'error',
            duration: 3000,
            position: 'top-center'
          })
        }
      }
    }
  }
}
